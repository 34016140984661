<template>
  <v-list-group :key="item.title" :prepend-icon="item.icon" :value="item.expanded || expanded">
    <template v-slot:activator>
      <v-list-item-title>{{ item.title }}</v-list-item-title>
    </template>
    <template v-for="subitem in item.children">
      <list-item :key="subitem.path" :item="subitem" style="margin-left:2em"></list-item>
    </template>
  </v-list-group>
</template>
<script>
import { computed } from '@vue/composition-api'
export default {
  components: {
    ListItem: () => import('./listItem')
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    expanded: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { root }) {
    const isLoggedIn = computed(() => { return root.$store.state.loggedIn })
    const roles = computed(() => { return root.$store.state.roles || {} })

    return {
      isLoggedIn,
      roles
    }
  }
}
</script>
